import request from '@/utils/request'


// 查询视频审核拒绝选项列表
export function listAuditRejectType(query) {
  return request({
    url: '/platform/video-audit-reject-type/list',
    method: 'get',
    params: query
  })
}

// 查询视频审核拒绝选项分页
export function pageAuditRejectType(query) {
  return request({
    url: '/platform/video-audit-reject-type/page',
    method: 'get',
    params: query
  })
}

// 查询视频审核拒绝选项详细
export function getAuditRejectType(data) {
  return request({
    url: '/platform/video-audit-reject-type/detail',
    method: 'get',
    params: data
  })
}

// 新增视频审核拒绝选项
export function addAuditRejectType(data) {
  return request({
    url: '/platform/video-audit-reject-type/add',
    method: 'post',
    data: data
  })
}

// 修改视频审核拒绝选项
export function updateAuditRejectType(data) {
  return request({
    url: '/platform/video-audit-reject-type/edit',
    method: 'post',
    data: data
  })
}

// 删除视频审核拒绝选项
export function delAuditRejectType(data) {
  return request({
    url: '/platform/video-audit-reject-type/delete',
    method: 'post',
    data: data
  })
}
